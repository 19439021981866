$color-primary: #FF6161;

$link-color: #969696;
$link-transition: initial;
$link-decoration: underline;

$link-hover-color: #E45252;
$link-hover-decoration: underline;

$chart-color-primary: #FF8585;
$chart-color-secondary: #ccc;